<template>
  <ion-page>
  <ion-header :translucent="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>enlever menu button et mettre close button ici un titre ou le logo</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content :fullscreen="true">
    <ion-header collapse="condense">
      <ion-toolbar>
        <ion-title size="large">ok</ion-title>
      </ion-toolbar>
    </ion-header>
      <ion-text>
        Logo & titre
        - choisir l icone de menu
      - choisir la couleur de l icone
      chosir le logo
      choisir la coleur du logo, la poice du type, le background
        Style global
        un choix de oculeur ou personnaliser
        taille des characteres
        police
        ICONE de l app
        la police, la couleur de police et l effet sur la police
        couleur du fond
        image de fond
        image de lancement
        couleur, texte, logo et splash screen pour ios et android
        arriere plan des pages, couleur, image mobile, image protrait, imge paysage
        couleur des separateurs
        MENU de navigation
        slate id un popover avec une croix pour ferme
        tab bar
        grid

        customiser une page :
        choisir le template eg
        carte photo, sous tire puis liste
        ou des evenements
        ou newsletter ou lines etc on peut ajouter

      </ion-text>


    <!-- Data to display after skeleton screen -->
    <div v-if="data">
      <div class="ion-padding">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac eros est. Cras iaculis pulvinar arcu non vehicula. Fusce at quam a eros malesuada condimentum. Aliquam tincidunt tincidunt vehicula.
      </div>

      <ion-list>
        <ion-list-header>
          <ion-label>
            Data
          </ion-label>
        </ion-list-header>
        <ion-item>
          <ion-avatar slot="start">
            <img src="../../assets/avatar.svg">
          </ion-avatar>
          <ion-label>
            <h3>
              {{ data.heading }}
            </h3>
            <p>
              {{ data.para1 }}
            </p>
            <p>
              {{ data.para2 }}
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-thumbnail slot="start">
            <img src="../../assets/avatar.svg">
          </ion-thumbnail>
          <ion-label>
            <h3>
              {{ data.heading }}
            </h3>
            <p>
              {{ data.para1 }}
            </p>
            <p>
              {{ data.para2 }}
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon :icon="call" slot="start"></ion-icon>
          <ion-label>
            <h3>
              {{ data.heading }}
            </h3>
            <p>
              {{ data.para1 }}
            </p>
            <p>
              {{ data.para2 }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <!-- Skeleton screen -->
    <div v-if="!data">
      <div class="ion-padding custom-skeleton">
        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      </div>

      <ion-list>
        <ion-list-header>
          <ion-label>
            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
          </ion-label>
        </ion-list-header>
        <ion-item>
          <ion-avatar slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-avatar>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-thumbnail slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-skeleton-text animated style="width: 27px; height: 27px" slot="start"></ion-skeleton-text>
          <ion-label>
            <h3>
              <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </p>
            <p>
              <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>



    </ion-content>
  </ion-page>
</template>

<script>
import { call } from 'ionicons/icons';
import { ref} from 'vue'
export default {
name: "Settings",
  setup() {
    const data = ref();

    setTimeout(() => {
      data.value = {
        'heading': 'Normal text',
        'para1': 'Lorem ipsum dolor sit amet, consectetur',
        'para2': 'adipiscing elit.'
      };
    }, 5000);

    return { data, call }
  }
};</script>

<style scoped>
/* Custom Skeleton Line Height and Margin */
.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}
</style>
